(function() {
    "use strict";

    // NOTE: Hanlebars helper to pluralize words, used in site header cart.
    Handlebars.registerHelper('pluralize', function(number, singular, plural) {
        if (number === 1) {
            return singular;
        } else {
            return (typeof plural === 'string' ? plural : singular + 's');
        }
    });

    // NOTE: Store commonly used vars.
    var $document = $(document),
        $htmlBody = $('html, body'),
        activeClass = 'active',
        notificationDelay = 4000,
        animationDuration = 400,
        scrollOffset = -30,

        checkLocalStorage = function() {
            // return false;
            var testKey = 'test';

              try {
                localStorage.setItem(testKey, '1');
                localStorage.removeItem(testKey);
                return true;
              } catch (error) {
                return false;
              }
        },

        getLocalStorage = function() {
            // NOTE: Function to get JSON data as localStorage.
            return JSON.parse(localStorage.getItem('cart'));
        },
        localStorageExist = checkLocalStorage(),
        cart = null,
        setLocalStorage = function() {
            // NOTE: Function to store JSON data as localStorage.
            if (checkLocalStorage()) {

                localStorage.setItem('cart', JSON.stringify(cart));
            }

        };


        if (localStorageExist) {
           cart = getLocalStorage();
        }

    // NOTE: If no cart object is present create one.
    if (cart === null) {

        cart = {
            amount: 0,
            items: {}
        };
        setLocalStorage();
    }

    var $siteHeader = $('.site-header'),
        $cart = $siteHeader.find('.cart'),
        cartTMPL = Handlebars.compile($cart.prev().html());

    $document.on('click', '.button-open-menu, .button-close-menu', function() {
        // NOTE: Click event to handle toggle showing/hiding of the menu.
        var $menu = $siteHeader.find('.menu-overlay');
        $menu.toggleClass(activeClass);

        if ($menu.hasClass(activeClass)) {
            $document.on('keyup.menu', function(e) {
                if (e.keyCode === 27) {
                    $menu.toggleClass(activeClass);
                    $document.off('keyup.menu');
                }
            });
        } else {
            $document.off('keyup.menu');
        }
    }).on('click', '.button-open-share', function() {
        // NOTE: Click event to handle share button. Used in site footer.
        $(this).parent().toggleClass(activeClass);
    }).on('click', '.button-scroll-top', function() {
        // NOTE: Scroll to top click event. Used in site footer.
        $htmlBody.stop().animate({
            scrollTop: 0
        }, animationDuration);
    }).on('click', '.extend-content button', function() {
        // NOTE: Click event to toggle showing/hiding content of extend content.
        $(this).toggleClass(activeClass).parent().next().toggleClass(activeClass);
    });

    // NOTE: Get and set handlebars template for the cart.
    $cart.html(cartTMPL(cart.amount));

    $siteHeader.on('click', '.button-open-search', function() {
        // NOTE: Click event to handle search inside of site header.
        var $this = $(this),
            $form = $siteHeader.find('form'),
            $input = $form.find('input').eq(0);

        $this.attr('disabled', 'disabled');
        $form.toggleClass(activeClass);
        $input.focus();

        $document.on('keyup.search', function(e) {
            // NOTE: Hide search input field if ESC key is pushed.
            if (e.keyCode === 27 && $input.is(':focus')) {
                $this.removeAttr('disabled');
                $form.toggleClass(activeClass);
                $document.off('keyup.search');
            }
        });
    }).on('click', '.button-expand', function() {
        // NOTE: Click event handler for sub menu.
        $(this).toggleClass(activeClass);
    });

    // NOTE: Code for handling the cookie notification.
    var cookieAlertName = 'cookie_alert',
        cookieAlert = Cookies.get(cookieAlertName);

    if (cookieAlert === undefined || !cookieAlert) {
        var $cookieAlert = $('.cookie-alert');

        $cookieAlert.addClass(activeClass).on('click', 'button', function() {
            Cookies.set(cookieAlertName, false, {
                expires: 365 // NOTE: Sets the cookie to expire after one year.
            });

            $cookieAlert.removeClass('active');
        });
    }

    // OPEN TAB WHEN TECHNICAL INFORMATION LINK
    $document.on('click touchstart', '.js-tech-info', function() {
      $('#fittings button').click();
    });

    // CONTACT MODAL
    $document.on('click touchstart', '.contact_overlay', function() {

        $(this).addClass('hidden');

        if ($(this).hasClass('js-inquiry_overlay')) {
            localStorage.clear();
            $('.product-list').addClass('hidden');

            if (window.location.href.indexOf("/en/") > -1) {
                $('.cart small').text('Contains no products');
            }
            else {
                $('.cart small').text('Innehåller nu inga produkter');
            }

            $(".cart a").removeAttr('href');
        }



    });


    $('.products').on('click', '.filter, .anchor-links', function() {
        // NOTE: Click event to handle anchor links and filter buttons dropdown shown in smaller devices on product listing template.
        var $this = $(this),
            clickEvent = function(e) {
                if ($(e.target).closest($this).length === 0) {
                    $this.removeClass(activeClass);
                    $document.off('click.dropdown', clickEvent);
                }
            };

        if ($this.hasClass(activeClass)) {
            $this.removeClass(activeClass);
            $document.off('click.dropdown');
        } else {
            $this.addClass(activeClass);
            $document.on('click.dropdown', clickEvent);
        }
    }).on('click', '.category button', function() {
        // NOTE: Handle previous and next for slick slider, shown on medium devices and down.
        var $this = $(this),
            $slick = $this.parent().find('.slick');

        if ($this.hasClass('button-next')) {
            $slick.slick('next');
        } else {
            $slick.slick('prev');
        }
    }).on('init', '.slick', function(e, slick, currentSlide) {
        var $category = $(this).closest('.category');
        var buttonPrev = '.button-prev';
        var buttonNext = '.button-next';

        if (slick.slideCount === 1) {
            $category.find(buttonNext).attr('disabled', 'disabled');
        }
    }).on('beforeChange', '.slick', function(e, slick, currentSlide, nextSlide) {
        // NOTE: Disable buttons if first/last slide.
        var direction = currentSlide < nextSlide ? 'right' : 'left';


        if ((nextSlide === 1 && direction === 'right') || (nextSlide === slick.slideCount - 1 && direction === 'right') || (nextSlide === slick.slideCount - 2 && direction === 'left') || nextSlide === 0 && direction === 'left') {

            var $category = $(this).closest('.category'),
                buttonPrev = '.button-prev',
                buttonNext = '.button-next';

            if (nextSlide === 1 && direction === 'right' && nextSlide !== (slick.slideCount -1)) {
                $category.find(buttonPrev).removeAttr('disabled');
            } else if (nextSlide === slick.slideCount - 1 && direction === 'right') {
                $category.find(buttonPrev).removeAttr('disabled');
                $category.find(buttonNext).attr('disabled', 'disabled');
            } else if (nextSlide === slick.slideCount - 2 && direction === 'left') {
                $category.find(buttonNext).removeAttr('disabled');
            } else if (nextSlide === (slick.slideCount)) {
                $category.find(buttonNext).attr('disabled', 'disabled');
            } else {
                $category.find(buttonPrev).attr('disabled', 'disabled');
            }
        }
    }).on('click', '.anchor-links a', function(e) {
        // NOTE: Animate click on anchor links.
        e.preventDefault();

        console.log("top: ", $($(this).attr('href')) );

        $htmlBody.stop().animate({
            scrollTop: $($(this).attr('href')).offset().top + scrollOffset - 55
        }, animationDuration);
    }).find('.slick').slick({
        // NOTE: Setup slick on product listing template.
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        responsive: [{
            breakpoint: 9000,
            settings: 'unslick'
        }, {
            breakpoint: 768,
            settings: {
                centerMode: true,
                variableWidth: true
            }
        }, {
            breakpoint: 544,
            settings: {
                centerMode: false
            }
        }]
    });

    var $product = $('.product'),
        notificationTimeout,
        replaceWeirdLetters = function($word) {
            return $word.replace(/å/gi, 'a').replace(/ä/gi, 'a').replace(/ö/gi, 'o').replace(/ /gi, '_').replace(/-/gi, '_').replace(/\//gi, '_').toLowerCase();
        };





    $product.on('click', '.button-add-to-cart', function() {
        // NOTE: Clear previous timeout to hide notification.
        clearTimeout(notificationTimeout);

        var $this = $(this),
            data = $product.data(),
            id = replaceWeirdLetters(data.id),
            isFitting = $this.attr('data-id') === 'fitting';

        if (cart.items[id] !== undefined) {
            if (!isFitting) {
                cart.amount += 1;
                cart.items[id].amount += 1;
            }
        }
        else {
            cart.amount += 1;

            cart.items[id] = {
                id: id,
                title: data.title,
                amount: 1,
                fittings: {}
            };
        }

        if (isFitting) {
            var dataFitting = $this.closest('tr').data(),
                idFitting = replaceWeirdLetters(dataFitting.id);

         //   cart.amount += 1;

            cart.items[id].fittings[idFitting] = {
                fittingId: idFitting,
                title: dataFitting.id/*,
                amount: 1*/
            };

            /*if (cart.items[id].fittings[idFitting] !== undefined) {
                cart.items[id].fittings[idFitting] += 1;
            }
            else {
                cart.items[id].fittings[idFitting] = {
                    title: dataFitting.id,
                    amount: 1
                };
            }*/
        }

        setLocalStorage();
        $cart.html(cartTMPL(cart.amount));

        var $notification = $cart.find('.notification'); // TODO: If moved outside of cart container declare outside this listner.

        $notification.addClass(activeClass);

        // NOTE: Set timeout to hide notification.
        notificationTimeout = setTimeout(function() {
            $notification.removeClass(activeClass);
        }, notificationDelay);

    }).on('click', '.colors button', function() {
        // NOTE: Click event to switch images for different colors.
        var $this = $(this),
            $colors = $product.find('.colors');
        $colors.find('.' + activeClass).removeClass(activeClass);
        $colors.find(':disabled').removeAttr('disabled');
        $this.addClass(activeClass).attr('disabled', 'disabled');
        $colors.find('img').eq($this.parent().index()).addClass(activeClass);
    }).find('.slick').slick({
        // NOTE: Setup slick for product page template.
        arrows: false,
        dots: true
    });

    var $inquiry = $('.inquiry'),
        $productList = $inquiry.find('.product-list'),
        confirmText = $inquiry.find('.product-list').data('confirmText'),
        productListSRC = $productList.prev().html();

    // NOTE: Check if product list is present and get and set handlebars template.
    if (productListSRC !== undefined) {
        var productListTMPL = Handlebars.compile(productListSRC);
        $productList.html(productListTMPL(cart.items));
    }



    $inquiry.on('click', '.button-plus, .button-minus, .button-remove', function() {
        // NOTE: Click event to handle adding or subtracting items inside inquiry.
        var $this = $(this),
            $li = $this.closest('li'),
            $inputs = $li.find('input[type="number"]'),
            $input = $inputs.eq(0),
            val = parseInt($input.val(), 10),
            isFitting = $li.data('fitting') ? true : false,
            id = !isFitting ? $this.attr("data-id") : $li.parent().parent('li').attr('data-id'),
            idFitting = isFitting ? $this.attr("data-id") : undefined;

        if ($this.hasClass('button-plus')) {
            if (val === 1) {
                $li.find('button:disabled').removeAttr('disabled');
            }
            $input.val(val + 1);

            if (!isFitting) {
                cart.items[id].amount += 1;
            } else {
                cart.items[id].fittings[idFitting].amount += 1;
            }
            cart.amount += 1;

            setLocalStorage();
            $cart.html(cartTMPL(cart.amount));
        }
        else if ($this.hasClass('button-minus') && val > 1) {
            if (val === 2) {
                $this.attr('disabled', 'disabled');
            }
            $input.val(val - 1);

            if (!isFitting) {
                cart.items[id].amount -= 1;
            } else {
                cart.items[id].fittings[idFitting].amount -= 1;
            }
            cart.amount -= 1;

            setLocalStorage();
            $cart.html(cartTMPL(cart.amount));
        }
        else if ($this.hasClass('button-remove')) {
            if (window.confirm(confirmText.replace('{0}', $li.find('label').text()))) {
                $li.remove();

                if(!isFitting){
                    var amount = 0;
                    cart.amount -= cart.items[id].amount;
                    for (var prop in cart.items[id].fittings) {
                        cart.amount -= cart.items[id].fittings[prop].amount;
                    }
                    delete cart.items[id];
                }
                else {
                    cart.amount -= cart.items[id].fittings[idFitting].amount;
                    delete cart.items[id].fittings[idFitting];
                }

                setLocalStorage();
                $cart.html(cartTMPL(cart.amount));
            }
        }
    });



    var currentUrl = window.location.href.split('#')[0],
        filters = currentUrl.split('?kategori='),
        activeBtns = [],
        filterBtns = $('[data-id="filterBtns"] button'),
        i,
        mobileFilterLabel = $('.mobileFilterLabel'),
        buildUrl = function() {
            var url = '';

            if (activeBtns.length > 0) {
                url += '?kategori=' + activeBtns[0];

                for (i = 1; i < activeBtns.length; i += 1) {
                    url += ',' + activeBtns[i];
                }
            }

            if (activeBtns.length === 1) {
                if (activeBtns[0] === 'all') {
                    url = '';
                }
            }

            window.location = currentUrl.split('?')[0] + url;
        };

    // Setting filter label on mobile
    for (i = 0; i < filterBtns.length; i += 1) {
        var $thisBtn = $(filterBtns[i]);
        if( $thisBtn.hasClass('active')) {
            mobileFilterLabel.text( $thisBtn.text() );
        }
    }

    filterBtns.on('click', function() {
        var thisBtn = $(this),
            thisId = thisBtn.attr('id');

        if (thisId === 'all') {
            activeBtns = [];
        } else {
            $('#all').removeClass('active');
            thisBtn.toggleClass('active');

            if (!thisBtn.hasClass('active')) {
                for (i = 0; i < activeBtns.length; i += 1) {
                    if (activeBtns[i] === thisId) {
                        activeBtns.splice(i, 1);
                        break;
                    }
                }
            } else {
                activeBtns.length = 0; //empty array before adding to the array to only show one catogory at a time
                activeBtns.push(thisId);
            }
        }

        buildUrl();
    });

    if (filters.length > 1) {
        activeBtns = filters[1].split(',');
    }

    var toggleForm = $("[data-id='toggleForm']");

    $("[data-id='openForm']").on('click', function(e) {
        e.preventDefault();

        toggleForm.addClass('form-open');
    });

    $("[data-id='closeForm']").on('click', function(e) {
        e.preventDefault();

        toggleForm.removeClass('form-open');
    });



    //Set equal heights on contact form columns
    if($('.js-contact').length && window.innerWidth > 991) {
        var contactForm = $('.js-contact'),
            adressBox = $('.js-address'),
            leftColHeight = $('.js-contact').find('.contact-text').outerHeight() + $('.js-contact').find('.vertical-align-bottom').outerHeight() + 30, //$('.js-contact').outerHeight(),
            rightColHeight = $('.js-address').outerHeight() + $('.cta.box').outerHeight() + 15,
            diffInHeight = Math.abs(leftColHeight - rightColHeight);

        if(leftColHeight > rightColHeight) {
            var newHeightRight = $('.js-address').outerHeight() + diffInHeight;
            adressBox.css({ 'height' : newHeightRight+'px'});
            contactForm.css({ 'height' : leftColHeight +'px'});
        } else if (leftColHeight < rightColHeight) {
            var newHeightLeft = leftColHeight + diffInHeight;
            contactForm.css({ 'height' : newHeightLeft+'px'});
        }
    }

    //Dropdown in contact form
    function DropDown(el) {
        this.dd = el;
        this.placeholder = this.dd.children('span');
        this.opts = this.dd.find('ul.dropdown > li');
        this.val = '';
        this.index = -1;
        this.initEvents();

        var selectedSubject = $('ul.dropdown').data('value');
        if(selectedSubject !== "") {
            $('.wrapper-dropdown span.form-control').text(selectedSubject);
        }
    }

    DropDown.prototype = {
        initEvents : function() {
            var obj = this;

            obj.dd.on('click', function(event){
                $(this).toggleClass('active');
                return false;
            });

            obj.opts.on('click',function(){
                var opt = $(this);
                obj.val = opt.text();
                $('input[name="tracker"]').val(opt.text());
                obj.index = opt.index();
                obj.placeholder.text(obj.val);
            });

        },
        getValue : function() {
            return this.val;
        },
        getIndex : function() {
            return this.index;
        }
    };

    function checkPos() {
        requestAnimationFrame(checkPos);

        var scroll = window.pageYOffset;
        if (scroll !== self.scrollDiff) {

            $('.button-scroll-top').css({'opacity':(( scroll )/200)+0.01});

            if ($(window).scrollTop() + $(window).height() > $('footer.footer').offset().top) {
                $('.button-scroll-top').addClass('not-sticky');
            } else {
                $('.button-scroll-top').removeClass('not-sticky');
            }
        }
        self.scrollDiff = scroll;
    }
    checkPos();
    $(".js-contact-btn").on('click', function(e) {
        e.preventDefault();
        $(this).next().toggleClass('active');
    });
    $(".button-close-flyout").on('click', function(e) {
        e.preventDefault();
        $('.contact-flyout').removeClass('active');
    });




    $(function() {
        var dd = new DropDown( $('#dd') );

        $(document).click(function() {
            $('.wrapper-dropdown').removeClass('active');
        });

    });

    $('.js-show-more').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);

        if($this.hasClass('open')) {
            $this.removeClass('open');
            $this.parent().next().slideUp();
        } else {
            $this.addClass('open');
            $this.parent().next().slideDown();
        }
    });

    $(function() {

        $(".video-box").on("click", function(e){
            e.preventDefault();

            var modal = $(this).find(".video-modal");
            
            if(modal.hasClass("open")){
                modal.removeClass("open");
            }else{
                modal.addClass("open");
            }
        });

        window.onclick = function(event) {
            if (event.target.className.indexOf("modal") === 0) {
                $(event.target).removeClass("open");                
            }
        };

    });

}());
